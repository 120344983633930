import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import UpvotyWidget from "../UpvotyWidget";
import { Box, Button, Stack } from "@mui/material";

import ChildSelector from "../ChildSelector";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faBullhorn } from "@fortawesome/pro-light-svg-icons";
import { pinwheelPalette } from "../../styles/theme";

import "./navigation.sass";
import ModalWindow from "../ModalWindow";
import { UpvotyOptions } from "../UpvotyWidget";
import { Notification } from "../NotificationCenter";
import { useIsWatchChild } from "@/hooks";

interface Props {
  showUpvotyBoard: (s: string) => Promise<void>;
  upvotyWidgetOptions: UpvotyOptions;
}

const MobileHeader = ({ upvotyWidgetOptions }: Props) => {
  const [isNewVersion, setIsNewVersion] = useState(
    // eslint-disable-next-line no-undef
    true,
  );
  const [isFeatureWidgetOpen, setFeatureWidgetOpen] = useState(false);
  const navigate = useNavigate();
  const mobileHeaderRef = useRef<HTMLDivElement | null>(null);
  const isWatchChild = useIsWatchChild();

  if (isNewVersion) {
    // eslint-disable-next-line no-undef
    localStorage.setItem("appVersion", import.meta.env.VITE_VERSION!);
    setTimeout(() => {
      setIsNewVersion(false);
    }, 3000);
  }

  useEffect(() => {
    if (mobileHeaderRef.current) {
      mobileHeaderRef.current.addEventListener("touchmove", (e) => {
        e.preventDefault();
      });
    }
  }, []);

  return (
    <>
      <Box
        component="nav"
        aria-label="mailbox folders"
        className="navbar"
        sx={{
          borderBottom: `1px solid ${pinwheelPalette.grey[5]}`,
          minHeight: { lg: "100vh" },
        }}
        ref={mobileHeaderRef}
      >
        <Box
          component="div"
          sx={{
            display: { xs: "flex", lg: "none" },
          }}
          className="navbar-mobile"
        >
          <div className="navbar-brand-child-selector">
            <NavLink className="navbar-item logo" to="/">
              &nbsp;
            </NavLink>
            <div className="childSection">
              <ChildSelector />
            </div>
          </div>
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap="8px">
            {!isWatchChild ? <Notification /> : null}
            <Button color="inherit" variant="text" sx={{ minWidth: 40, minHeight: 40 }} className="stonly-product-news-selector">
              <FontAwesomeIcon icon={faBullhorn} style={{ fontSize: "24px" }} />
            </Button>
            <Button
              color="inherit"
              variant="text"
              sx={{ minWidth: 40, minHeight: 40 }}
              onClick={() => {
                navigate("/settings/account");
              }}
            >
              <FontAwesomeIcon icon={faGear} style={{ fontSize: "24px" }} />
            </Button>
          </Stack>
        </Box>
      </Box>
      <ModalWindow
        headerText="Feature Request"
        showModal={isFeatureWidgetOpen}
        setShowModal={setFeatureWidgetOpen}
        renderBody={() => <UpvotyWidget options={upvotyWidgetOptions} />}
        isLarge
        cancelButtonText="Close"
      />
    </>
  );
};

export default MobileHeader;
