import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import "./header.sass";

import { useMeContext } from "@/util/me-context";
import ChildContext from "../../context/child";
import ModalEnableGroupTexting from "../ModalEnableGroupTexting";
import FeedbackSurvey from "../FeedbackSurvey";
import ModalWindow from "../ModalWindow";
import UpvotyWidget, { UpvotyOptions } from "../UpvotyWidget";
import { enableNotifications } from "../../util/fcm-notification-helper";
import BulbMenuModal from "../BulbMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulbOn, faAngleRight, faArrowRightFromBracket, faGear } from "@fortawesome/pro-light-svg-icons";
import { Box, Button, IconButton, Typography, alpha } from "@mui/material";
import MobileHeader from "../Navigation/MobileHeader";
import { Widget } from "@typeform/embed-react";

import { differenceInCalendarDays, getWeekOfMonth } from "date-fns";
import { Notification } from "../NotificationCenter";
import { ContactPhoto } from "../ContactPhoto";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { pinwheelPalette } from "@/styles/theme";
import { useLocalStorage } from "@/hooks/use-localstorage";
import WelcomeScheduleModal from "../WelcomeScheduleModal";
import CancelModal from "../WelcomeScheduleModal/cancel-modal";
import { useIsWatchChild } from "@/hooks";

interface Props {
  onLogoutPress: () => Promise<void>,
  showUpvotyBoard: (s: string) => Promise<void>;
  upvotyWidgetOptions: UpvotyOptions,
}

interface NPSPayload {
  created_at: string;
  feedback?: boolean;
  dismiss?: boolean;
  feedbackAttempt: number;
  userId: string;
}

const Header = ({
  onLogoutPress,
  showUpvotyBoard,
  upvotyWidgetOptions,
}: Props) => {
  const { data: me, caregiverData } = useMeContext();
  const { child } = useContext(ChildContext);
  const [open, setOpen] = useState(false);
  const [showEnableGroupTexting, setShowEnableGroupTexting] = useState(false);
  const [isModalMenuOpen, setModalMenuOpen] = useState(false);
  const [isFeatureWidgetOpen, setFeatureWidgetOpen] = useState(false);
  const [isTypeFormWidgetOpen, setNPSWidgetOpen] = useState(false);
  const [isNPSFeedback, setNPSFeedback] = useState(false);
  const [isNewVersion, setIsNewVersion] = useState(
    // eslint-disable-next-line no-undef
    import.meta.env.VITE_VERSION !== localStorage.getItem("appVersion")
  );
  const [welcomeScheduleModal, setWelcomeScheduleModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showNPSBanner, setShowNPSBanner] = useState(false);
  const [feedbackAttempt, setFeedbackAttempt] = useState(0);
  const currentDate = useMemo(() => new Date(), []);
  const [visitedSchedulePage, setVisitedSchedulePage] = useLocalStorage("visitedSchedulePage", false);
  const location = useLocation();
  const isWatchChild = useIsWatchChild();

  if (isNewVersion) {
    // eslint-disable-next-line no-undef
    localStorage.setItem("appVersion", import.meta.env.VITE_VERSION!);
    setTimeout(() => {
      setIsNewVersion(false);
    }, 3000);
  }

  const showGroupNPSBanner = useCallback(()=>{
    if (me) {
      const weekNumber = getWeekOfMonth(currentDate);
      const groupNumber = userGroup(me.userId);
      
      if (weekNumber === 1 && groupNumber <=1) {
        setShowNPSBanner(true);
      } else if (weekNumber === 2 && groupNumber <=3 && groupNumber > 1) {
        setShowNPSBanner(true);
      } else if (weekNumber === 3 && groupNumber <=5 && groupNumber > 3) {
        setShowNPSBanner(true);
      } else if (weekNumber === 4 && groupNumber <=7 && groupNumber > 5) {
        setShowNPSBanner(true);
      } else if (weekNumber === 5 && groupNumber <=9 && groupNumber > 7) {
        setShowNPSBanner(true);
      }
    }
  }, [me, currentDate]);

  useEffect(() => {
    const checkNPS = localStorage.getItem("stonlyUserData");
    const npsPayload: NPSPayload = checkNPS ? JSON.parse(checkNPS) : {};
    if (!npsPayload.dismiss) {
      if (caregiverData && caregiverData.userCreateDate) {
        if (npsPayload.userId !== me?.attributes.sub) {
          localStorage.removeItem("stonlyUserData");
        }
        const firstLoginDiff = differenceInCalendarDays(currentDate, new Date(caregiverData.userCreateDate));
        const lastFeedbackDiff = differenceInCalendarDays(currentDate, new Date(npsPayload.created_at));        
        setFeedbackAttempt(npsPayload.feedbackAttempt ? npsPayload.feedbackAttempt : 0);
        if (npsPayload.userId === me?.attributes.sub && lastFeedbackDiff > 90) {
          showGroupNPSBanner();          
        } else if (firstLoginDiff > 45 && !npsPayload.userId) {
          showGroupNPSBanner();
        }
      }
    }

  }, [caregiverData, currentDate, me?.attributes.sub, showGroupNPSBanner]);

  // User group by first char
  function userGroup(userId: string): number {
    const groupId =  Array.from(userId).find((e)=>{
      if (Number(e)){
        return e;
      }
    });
    return Number(groupId);
  }

  useEffect(() => {
    // when changes occur in this page during hot reload
    // Firebase App named '[DEFAULT]' already exists, It will occur on hot relado.
    enableNotifications();
  }, []);

  const feedbackNPSWidget = () => {
    setNPSWidgetOpen(true);
  } 
  const dismissNPSWidget = () => {
    const npsDismissData = {
      userId: me?.attributes.sub,
      created_at: currentDate,
      dismiss: true,
    }
    localStorage.setItem("stonlyUserData", JSON.stringify(npsDismissData));
    setShowNPSBanner(false)
  }

  useEffect(() => {
    if (isNPSFeedback) {
      const npsFeedBackDay = {
        userId: me?.attributes.sub,
        created_at: currentDate,
        feedback: true,
        feedbackAttempt: feedbackAttempt + 1
      }
      localStorage.setItem("stonlyUserData", JSON.stringify(npsFeedBackDay));
      setShowNPSBanner(false)
    }
  }, [currentDate, feedbackAttempt, isNPSFeedback, me?.attributes.sub]);

  const handleSetUp = async () => {
    setShowEnableGroupTexting(true);
  };

  const showNotification = () => {
    const hasDeviceToken =
      child?.deviceInfos && child.deviceInfos.some(deviceInfo => !!deviceInfo.fcmDeviceToken);
    if (hasDeviceToken && !child.configuration.allowGroupMessaging) return "enableGroupTexting";
    return null;
  };

  const getHeaderNotice = () => {
    if (!visitedSchedulePage && location.pathname === "/calendar") {
      return (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "16px",
            "@media (max-width: 414px)": {
              marginBottom: "10px",
              p: {
                fontSize: "14px",
                flex: "1 1 0",
              },
            },
          }}
        >
          <Typography variant="body1">Schedules Tutorial Video</Typography>
          <Box>
            <Button
              sx={{ mr: 2 }}
              size="small"
              onClick={() => {
                setWelcomeScheduleModal(true);
                setVisitedSchedulePage(true);
              }}
            >
              Watch
            </Button>
            <IconButton
              sx={{ width: "24px", height: "24px"}}
              onClick={() => {
                setConfirmModal(true);
              }}
            >
              <FontAwesomeIcon icon={faXmark} size="xs" color={alpha(pinwheelPalette.black[100], 0.75)} />
            </IconButton>
          </Box>
        </Box>
      );
    } else if (showNotification() === "enableGroupTexting") {
      return (
        <div className="enable-group-texting">
          <p>Enable group texting</p>
          <Button onClick={handleSetUp} size="small">
            Set up
          </Button>
        </div>
      );
    } else if (showNPSBanner) {
      return <FeedbackSurvey dismissNPSWidget={dismissNPSWidget} feedbackNPSWidget={feedbackNPSWidget} />
    } else {
      return null;
    }
  };

  return (
    <div className="dashboard-header-wrapper">
      <div className="dashboard-header">
        <Box className="right" sx={{ display: { xs: "none", lg: "block" } }}>
          <div>
            <Box
              role="button"
              className={`bulb ${isNewVersion ? "animated" : ""}`}
              sx={{
                display: {
                  xs: "none",
                  lg: "flex",
                },
              }}
              onClick={(e) => {
                setModalMenuOpen(true);
                setIsNewVersion(false);
                setAnchorEl(e.currentTarget);
              }}
              aria-controls={isModalMenuOpen ? "bulb-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isModalMenuOpen ? "true" : undefined}
            >
              <FontAwesomeIcon title="Feature Request" icon={faLightbulbOn} size="lg" />
            </Box>
            <BulbMenuModal
              isModalMenuOpen={isModalMenuOpen}
              setModalMenuOpen={setModalMenuOpen}
              showUpvotyBoard={showUpvotyBoard}
              setFeatureWidgetOpen={setFeatureWidgetOpen}
              anchorEl={anchorEl}
            />
          </div>
          <Box sx={{ display: { xs: "none", lg: "block" }, margin: "0 8px" }}>
            {!isWatchChild ? <Notification /> : null}
          </Box>
          <div className="account">
            <div className="info" onClick={() => setOpen(!open)}>
              <FontAwesomeIcon className="account-dropdown-icon" icon={faAngleRight} rotation={open ? 270 : 90} />
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <div>{me ? `${me.attributes["given_name"] || ""} ${me.attributes["family_name"] || ""}` : ""}</div>
                <Box sx={{ width: "32px", height: "32px" }}>
                  <ContactPhoto name={me ? `${me.attributes["given_name"] || ""}` : ""} size="small" />
                </Box>
              </Box>
            </div>
            {open && (
              <div className="account-dropdown">
                <ul>
                  <li>
                    <span>
                      <NavLink onClick={() => setOpen(false)} to="/settings/account">
                        <FontAwesomeIcon className="settings-icon" icon={faGear} /> Settings
                      </NavLink>
                    </span>
                  </li>
                  <li className="logout">
                    <span onClick={onLogoutPress}>
                      <FontAwesomeIcon className="logout-icon" icon={faArrowRightFromBracket} /> Log out
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Box>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <MobileHeader showUpvotyBoard={showUpvotyBoard} upvotyWidgetOptions={upvotyWidgetOptions} />
        </Box>
        <ModalWindow
          headerText="Feature Request"
          showModal={isFeatureWidgetOpen}
          setShowModal={setFeatureWidgetOpen}
          renderBody={() => <UpvotyWidget options={upvotyWidgetOptions} />}
          isLarge
          cancelButtonText="Close"
        />
        <ModalWindow
          headerText="Feedback"
          showModal={isTypeFormWidgetOpen}
          setShowModal={setNPSWidgetOpen}
          renderBody={() => (
            <Widget
              id="LzxHvwVm"
              style={{ width: "100%", height: "400px" }}
              className="feedback-form"
              hidden={{
                email: me?.attributes.email || "",
              }}
              inlineOnMobile
              onSubmit={() => {
                setNPSWidgetOpen(false);
                setNPSFeedback(true);
              }}
            />
          )}
          isLarge
          cancelButtonText="Close"
        />
        <div className="header-notice-wrapper">{getHeaderNotice()}</div>

        {showEnableGroupTexting && child ? (
          <ModalEnableGroupTexting isModalShown={showEnableGroupTexting} setShowModal={setShowEnableGroupTexting} child={child} />
        ) : null}

        <WelcomeScheduleModal
          showModal={welcomeScheduleModal}
          setShowModal={(close: boolean) => {
            setWelcomeScheduleModal(close);
          }}
          welcomeModalSteps={1}
        />
        <CancelModal 
          showModal={confirmModal}
          setShowModal={(close: boolean) => {
            setConfirmModal(close);
          }}
          onActionButtonPress={async () => {
            setConfirmModal(false);
            setVisitedSchedulePage(true);
          }}
        />
      </div>
    </div>
  );
};

export default Header;